import {NavigationMobile} from "../components/common/navigation/navigationMobile";
import {NavigationBase} from "../components/common/navigation/navigationBase";
import React from "react";
import {Row} from "../components/common/grid/row";
import {Column} from "../components/common/grid/column";
import astronaute from "../../style/image/astronaute-interrogation.png";
import philippe from "../../style/image/Philippe.jpg";
import corentin from "../../style/image/Corentin.jpg";
import mathias from "../../style/image/Mathias.jpg";
import william from "../../style/image/William.jpg";
import ImplexLogoSvg from "../../style/svg/implexLogoSvg";
import {Footer} from "../components/common/navigation/footer";
import {useTranslation} from "react-i18next";
import {useSavePosScrollBar} from "../helper/useSavePosScrollBar";
import {useRefreshItemNav} from "../helper/useRefreshItemNav";
import {homeId} from "./preLaunch";


export const whoWeAreId = 'whoWeAre'
const WhoWeAre = () => {
    const {} = useSavePosScrollBar(whoWeAreId)
    const {} = useRefreshItemNav(whoWeAreId)
    const {t, i18n} = useTranslation()
    return (
        <>
            <header>
                <NavigationMobile blue={true}/>
                <NavigationBase blue={true}/>
            </header>
            <div className='whoAreYou-container'>
                <Row customClass={'jc-center'}>
                    <Column xl={8}>
                        <Row customClass={'jc-center header-size'}>
                            <Column xl={6} customClass={'pt-100 header-title'}>
                                <a href={'https://www.implex.fr'} target={'_blank'}><ImplexLogoSvg style={{height:'130px', display:'block', margin:'0 auto'}}/></a>
                                <h1>{t('whoWeAre:title')}</h1>
                                <h2>{t('whoWeAre:forThe')} <span>{t('whoWeAre:metrology')}</span> {t('whoWeAre:a')} <span>{t('whoWeAre:quality')}</span> {t('whoWeAre:and')} <span>{t('whoWeAre:dataAnalyse')}</span></h2>
                            </Column>
                            <Column xl={6}>
                                <img className={'w-100 header-img'} src={astronaute}/>
                            </Column>
                        </Row>
                        <Row customClass={'jc-center'}>
                            <Column min={12}>
                                <h2 className={'text-center'}>{t('whoWeAre:implexTitle')}</h2>
                            </Column>
                        </Row>
                        <Row customClass={'jc-center'}>
                            <Column min={12} customClass={'block'}>
                                <p>{t('whoWeAre:implexSubTitle')}</p>
                                <ul className={'ul-text'}>
                                    <li>{t('whoWeAre:implexLi')}</li>
                                    <li>{t('whoWeAre:implexLi2')} <span style={{marginRight:''}}> {t('whoWeAre:ddal')}</span> </li>
                                </ul>
                                <p>{t('whoWeAre:implexParagraph')}</p>
                            </Column>
                        </Row>
                        <Row customClass={'jc-center mt-60'}>
                            <h2>{t('whoWeAre:team')}</h2>
                        </Row>
                        <Row customClass={'jc-center gap-40'}>
                            <CharacterBlock img={philippe} job={t('whoWeAre:ceo')} name={'Philippe'} />
                            <CharacterBlock img={corentin} job={t('whoWeAre:architect')} name={'Corentin'}/>
                            <CharacterBlock img={william} job={t('whoWeAre:developper')} name={'William'}/>
                            <CharacterBlock img={mathias} job={t('whoWeAre:designer')} name={'Mathias'}/>
                        </Row>
                        <Row customClass={'jc-space-between mt-60'}>
                            <Column style={{display:'flex', flexDirection:'column'}} lg={5} min={12}>
                                <h2>{t('whoWeAre:job')}</h2>
                                <div className={'block'} style={{height:'-webkit-fill-available'}}>
                                    <p>
                                        {t('whoWeAre:jobParagraph')}
                                    </p>
                                </div>
                            </Column>
                            <Column lg={5} min={12}>
                                <h2>{t('whoWeAre:vocation')}</h2>
                                <div className={'block'}>
                                    <p>
                                        {t('whoWeAre:vocationParagraph')}
                                    </p>
                                </div>
                            </Column>
                        </Row>
                        <Row customClass={'jc-center mt-60'}>
                            <Column min={12}>
                                <h2 className={'text-center'}>{t('whoWeAre:policyInnovation')}</h2>
                            </Column>
                        </Row>
                        <Row customClass={'jc-center'}>
                            <Column min={12} customClass={'block'}>
                                <p>{t('whoWeAre:policyInnovationParagraph')}</p>
                            </Column>
                        </Row>
                        <Row customClass={'jc-center mt-60'}>
                            <Column min={12}>
                                <h2 className={'text-center'}>{t('whoWeAre:policyQuality')}</h2>
                            </Column>
                        </Row>
                        <Row customClass={'jc-center'}>
                            <Column min={12} customClass={'block'}>
                                <p>{t('whoWeAre:policyQualityParagraph')} <a href={'https://www.implex.fr/telechargements/Implex_Qualicy_Policy.pdf'} target={'_blank'}>{t("demonstrator:here")}</a></p>
                            </Column>
                        </Row>
                    </Column>
                </Row>
            </div>
            <Footer preLaunch={false}/>
        </>

    )
}

type CharacterBlockProps = {
    img?: string;
    name?: string;
    job?: string;
}

const CharacterBlock = ({img,name,job}:CharacterBlockProps) => {
    return(
        <Column lg={2} md={3} min={12} customClass={'character-block'}>
            <img src={img}/>
            <p>{name}</p>
            <h3>{job}</h3>
        </Column>
    )
}
export default WhoWeAre